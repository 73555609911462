import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";
import { NavLink } from "react-router-dom";

const StatBox = ({ title, subtitle, icon, progress, increase, path }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      width="250px"
      height="100px"
      backgroundColor={colors.primary[400]}
      borderRadius="20px"
      paddingY="20px"
      paddingX="10px"
    >
      <NavLink
        to={path}
        style={{ textDecoration: "none", color: "inherit", width: "100%" }}
      >
        <Box display="flex" width="100%">
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {subtitle}
            </Typography>
            
            {icon}
          </Box>
         
        </Box>
      </NavLink>
      <NavLink style={{ textDecoration: "none", color: "inherit" }} to={path}>
        {" "}
        <Box display="flex" justifyContent="space-between" mt="2px">
          <Typography variant="h3" sx={{ color: colors.greenAccent[500] , paddingLeft:"8px" }}>
            {title}
          </Typography>
          <Typography
            variant="h5"
            fontStyle="italic"
            sx={{ color: colors.greenAccent[600] }}
          >
            {increase}
          </Typography>
        </Box>
      </NavLink>
    </Box>
  );
};

export default StatBox;

