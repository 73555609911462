import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, IconButton, useTheme, Menu, MenuItem , Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { deleteCookie } from "../../components/Cookies";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Person2Icon from '@mui/icons-material/Person2';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Sidebar from "./Sidebar";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MobileSidebar from "./MobileSidebar";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const Topbar = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const Navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLogging, setisLogging] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = React.useState(null);
  const [state, setState] = React.useState({
    right: false,
  });






  const logoutUser = () => ({
    type: 'LOGOUT_USER',

  });


  const handleLogout = () => {
    deleteCookie("leadmanagement");
    dispatch(logoutUser());
    toast.success('Logout successfully');
    return Navigate('/')
  };

  // const handleLogout = () => {
  //   setisLogging(false);
  //   localStorage.removeItem('loginAdmin');
  //   navigate("/")
  // };

  
  const [anchorE1l, setAnchorE1l] = useState(null);
  const [isOpen11, setIsOpen11] = useState(false);

const clickMobileIcon =(event) =>{
  setAnchorE1l(event.currentTarget);
  setIsOpen11(!isOpen11);
  setIsBackgroundBlur(true);
}

const handleClose1 = () => {
  setAnchorE1l(null);
  setIsOpen11(false);
  setIsBackgroundBlur(false);
};






  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  
  const [isOpen1, setIsOpen1] = useState(false);

  const toggleSidebar = () => {
    setIsOpen1(!isOpen1);
    setIsBackgroundBlur(true);
  };

  const closeSidebar = () => {
    setIsOpen1(false);
    setIsBackgroundBlur(false);
  };




  const [isBackgroundBlur, setIsBackgroundBlur] = useState(false);




  return (
<>


<Box display="flex" justifyContent="space-between" p={1} className="mobileHideSidebar" style={{ position: "sticky", top: "0", backgroundColor: "white", zIndex: "9"   }} >
      {/* SEARCH BAR */}
      <Box display="flex" borderRadius="20px" border="1px solid rgb(233, 228, 228)">
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" >
          <SearchIcon />
        </IconButton>
      </Box>

     

      {/* ICONS */}
      <Box display="flex">
        <IconButton>
          <NavLink to="">
            <NotificationsOutlinedIcon />
          </NavLink>
        </IconButton>
        <IconButton>
          <NavLink to="  ">
            <SettingsOutlinedIcon />
          </NavLink>
        </IconButton>


         {/* DROPDOWN */}


         <IconButton onClick={handleClick} style={{ fontSize: "15px" }}>
        <Person2Icon /> Admin {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem 
      
         >
          <NavLink to="" style={{textDecoration:"none" , color:"black"}}>My Profile</NavLink>
        </MenuItem>
        <MenuItem
   
         >
          <NavLink to="" style={{textDecoration:"none" ,  color:"black"}}>Settings</NavLink>
        </MenuItem> */}
        <MenuItem onClick={() => {
          handleClose();
          handleLogout();
        }}>
          Logout
        </MenuItem>
      </Menu>


        {/* {isLogging === true ? "" :
          <div onClick={handleLogout}>
            <h6 style={{ cursor: "pointer", color: "#696969" }}>Logout</h6>
          </div>} */}
        <ToastContainer />
      </Box>



    </Box>

    <Box  className="mobileshowtop" style={{display:"none", position: "sticky", top: "0", backgroundColor: "white", zIndex: "99"  }}>

      <div style={{display:"flex" , justifyContent:"space-between" , alignItems:"center"}}> 

      <div style={{display:"flex" , justifyContent:"space-between", alignItems:"center", gap:"20px"}}> 
      <MenuOutlinedIcon onClick={toggleSidebar} style={{cursor:"pointer"}}/>
      <img src="/img/logo.png" alt="" />
    </div>

      <MoreVertOutlinedIcon onClick={clickMobileIcon}  style={{cursor:"pointer"}} />
      <Menu
        anchorEl={anchorE1l}
        open={Boolean(anchorE1l)}
        onClose={handleClose1}
      >
        {/* <MenuItem 

         >
          <NavLink to="" style={{textDecoration:"none" , color:"black"}}>My Profile</NavLink>
        </MenuItem>
        <MenuItem
 
         >
          <NavLink to="" style={{textDecoration:"none" ,  color:"black"}}>Settings</NavLink>
        </MenuItem> */}
        <MenuItem onClick={() => {
          handleClose1();
          handleLogout();
        }}>
          Logout
        </MenuItem>
      </Menu>
      
      </div>

      {isBackgroundBlur && <div className="background-blur" />}
    </Box>



    <Box className={`sidebar ${isOpen1 ? 'open' : ''}`}>

    <Box display="flex" justifyContent="space-between" alignItems="center" style={{padding:"10px 0px 10px 15px"}} >
                  <img src="/img/logo.png" alt="" />
                  <IconButton onClick={closeSidebar}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </Box>
    {/* <Button onClick={closeSidebar}>Close Sidebar</Button> */}
    <MobileSidebar/>
      </Box>
   




    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(Topbar);
