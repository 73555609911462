import React, { useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Button,
} from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { useParams } from "react-router-dom";
import { getCookie } from "../../components/Cookies";
// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ManagerDetails = ({ onClose }) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };

  const [adminData, setAdminData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await Api.getAdminById(id);
      console.log(response.data.data);
      setAdminData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const backToPage = () => {
    navigate("/users");
  };

  return (
    <>
      <Topbar />
      {adminData && (
        <>
          <Box m="20px" className="margin_in_mobile_ipad">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 20px",
                alignItems: "center",
                backgroundColor: "white",
                zIndex: "9999999",
                borderRadius: "10px",
              }}
            >
              <div style={{ display: "flex", padding: "20px" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    sx={{
                      backgroundColor: "#9B56FF",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF",
                      },
                    }}
                    onClick={backToPage}
                  >
                    {" "}
                    Back
                  </Button>
                </div>
              </div>
            </div>

            <div
              style={{ padding: " 20px", alignItems: "center" }}
              className="padding_in_mobile_ipad"
            >
              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Admin Information
                  </Typography>

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      gridTemplateColumns: "auto auto   ",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <h3>
                      {" "}
                      <strong> First Name :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData.firstName || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong> Last Name :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData.lastName || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong> Email :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData.email || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong> Phone :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData.mobileNumber || "No"}
                      </span>
                    </h3>

                    <h3>
                      <strong>User Join Date :</strong>
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData?.createdAt
                          ? adminData.adminData.createdAt.substring(0, 10)
                          : "No"}
                      </span>
                    </h3>
                  </Box>
                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Company Information
                  </Typography>
                 

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      gridTemplateColumns: "auto auto auto ",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <h3>
                      {" "}
                      <strong> Industry :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData.industry || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong> Owner :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData.owner || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong> WebSite :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData.website || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong> Number of Employees :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData.employNumber || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong> Lead Source :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.adminData.leadSource || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong> Total Manager and Agent :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {adminData.userDataCount || "No"}
                      </span>
                    </h3>
                  </Box>
                </Box>
              </div>
              <Box
                sx={{
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  padding: "20px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight="550"
                  sx={{ m: "0 0 5px 0" }}
                  className="heading_animation"
                >
                  Admin User Information
                </Typography>

              

                <Box
  component="form"
  className="LMS-Info-section"
  sx={{
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)", // Adjust based on number of fields you have
    gap: "20px",
    padding: "20px",
  }}
  noValidate
  autoComplete="off"
>
  {adminData.userData.map((data, index) => (
    <React.Fragment key={index}>
      <div style={{ gridColumn: "span 1" }}>
        <h3>
          <strong>First Name:</strong>{" "}
          <span style={{ fontWeight: "600" }}>
            {data.firstName || "No"}
          </span>
        </h3>
      </div>
      <div style={{ gridColumn: "span 1" }}>
        <h3>
          <strong>Last Name:</strong>{" "}
          <span style={{ fontWeight: "600" }}>
            {data.lastName || "No"}
          </span>
        </h3>
      </div>
      <div style={{ gridColumn: "span 1" }}>
        <h3>
          <strong>Role:</strong>{" "}
          <span style={{ fontWeight: "600" }}>
            {data.role || "No"}
          </span>
        </h3>
      </div>
      <div style={{ gridColumn: "span 1" }}>
        <h3>
          <strong>CreatedAt:</strong>{" "}
          <span style={{ fontWeight: "600" }}>
            {data?.createdAt ? data.createdAt.substring(0, 10) : "No"}
          </span>
        </h3>
      </div>
    </React.Fragment>
  ))}
</Box>
              </Box>
              <br />
            </div>
            <ToastContainer />
          </Box>
        </>
      )}
    </>
  );
};

export default ManagerDetails;
