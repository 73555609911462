// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/img/image.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.app {
  display: flex;
  /* position: relative; */
}

.yash ::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}





.bgg-img {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: 50%;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-color: #F4F5FF;
}
.css-yz9k0d-MuiInputBase-input{
  font:message-box !important;
  padding-left: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;;EAKE,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,gBAAgB;AAClB;;AAEA,oBAAoB;AACpB;EACE,gBAAgB;EAChB,eAAe;AACjB;;;;;;AAMA;EACE,yDAA8C;EAC9C,wBAAwB;EACxB,yBAAyB;EACzB,4BAA4B;EAC5B,yBAAyB;AAC3B;AACA;EACE,2BAA2B;EAC3B,6BAA6B;AAC/B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap\");\n\nhtml,\nbody,\n#root,\n.app,\n.content {\n  height: 100%;\n  width: 100%;\n  overflow-x: hidden;\n}\n\n.app {\n  display: flex;\n  /* position: relative; */\n}\n\n.yash ::-webkit-scrollbar {\n  width: 2px;\n}\n\n::-webkit-scrollbar {\n  width: 12px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #e0e0e0;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #888;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n  cursor: pointer;\n}\n\n\n\n\n\n.bgg-img {\n  background-image: url(\"/public/img/image.png\");\n  background-position: 50%;\n  background-size: 100% 60%;\n  background-repeat: no-repeat;\n  background-color: #F4F5FF;\n}\n.css-yz9k0d-MuiInputBase-input{\n  font:message-box !important;\n  padding-left: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
